.mainBox {
  height: 100vh;
}

.info-header {
  font-size: large;
}

.stepperBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.stepImageBox {
  background-image: url("../../assets//onboardingBGImage.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.transa{
  width: 90% !important;
}

.transa100 {
  width: 100% !important;
}

.transa40 {
  width: 40% !important;
}

.sweapableContainer {
  width: 90vw;
}

.stepImage {
  min-width: 280px;
  max-width: 325px;
  min-height: 280px;
  max-height: 325px;
}

.stepTitle {
  text-align: center;
  background: linear-gradient(#f9e006, #ff6738);
  background: -webkit-linear-gradient(#f9e006, #ff6738);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contentBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marl {
  margin-left: 12%;
 
}

.linkHead {
  font-size: 30px;
  font-weight: 500;
  color: #3063E9;
}

.linkA {
  font-size: 18px;
  font-weight: 300;
  color: #202020;
}